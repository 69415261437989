// routes
import { PATH_LOGGED_IN } from './routes/paths';
// components
import SvgIconStyle from '@mymeeinc/shared-web/components/mui/SvgIconStyle';
import Label from '@mymeeinc/shared-web/components/Label';
import { AcuityAvailability } from '@mymeeinc/types/acuity';
import { SuccessIcon, WarningIcon } from '@mymeeinc/shared-web/theme/overrides/CustomIcons';
import { ConversationList } from '@mymeeinc/shared-web/hooks/useChat';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

const navConfig = (conversations: ConversationList, acuityDocument?: AcuityAvailability) => {
  const unreadConversations = Object.entries(conversations.byId).filter(
    ([, convo]) => convo.unreadCount > 0
  ).length;
  const navConfig = [
    {
      subheader: 'coaching',
      items: [
        { title: 'Dashboard', path: PATH_LOGGED_IN.dashboard, icon: ICONS.dashboard },
        // USER
        {
          title: 'user management',
          path: PATH_LOGGED_IN.member.list,
          icon: ICONS.user,
          children: [{ title: 'assigned members', path: PATH_LOGGED_IN.member.list }],
        },
        {
          title: 'acuity integration',
          path: PATH_LOGGED_IN.acuity,
          icon: ICONS.calendar,
          info: acuityDocument?._id ? <SuccessIcon /> : <WarningIcon />,
        },
      ],
    },
    {
      subheader: 'app',
      items: [
        // {
        //   title: 'mail',
        //   path: PATH_LOGGED_IN.mail.root,
        //   icon: ICONS.mail,
        //   info: <Label color="error">+1</Label>,
        // },
        {
          title: 'chat',
          path: PATH_LOGGED_IN.chat.root,
          icon: ICONS.chat,
          info: unreadConversations > 0 ? <Label color="error">{unreadConversations}</Label> : null,
        },
        {
          title: 'calendar',
          path: PATH_LOGGED_IN.calendar,
          icon: ICONS.calendar,
          info: <Label color="warning">+1</Label>,
        },
        // { title: 'Todo', path: PATH_LOGGED_IN.kanban, icon: ICONS.kanban },
      ],
    },
  ];

  return navConfig;
};
export default navConfig;
