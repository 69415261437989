// import React from 'react';
import ReactDOM from 'react-dom/client';

import realm_config from './realm.json';
import CoachWebApp from './App';
import { defaultSettings } from './config';

import { name, version } from '../package.json';
import { MainFrame } from '@mymeeinc/shared-web/mainFrame';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <MainFrame
    realm_config={realm_config}
    name={name}
    version={version}
    sentry_init={{
      dsn: 'https://b054f239f1074a1eb792d640ef88ada0@o685251.ingest.sentry.io/4505346761818112',
    }}
    defaultSettings={defaultSettings}
  >
    <CoachWebApp />
  </MainFrame>
);
