import { gql } from '@apollo/client';
import { useState } from 'react';
import { useApolloClient } from './useApolloClient';
import {
  AcuityAvailability,
  AcuityHookFn,
  InsertAcuityFn,
  RemoveAcuityFn,
} from '@mymeeinc/types/acuity';
import { useRealmApp } from '../components/RealmApp';
import useSettings from './useSettings';
import watchOne from './watch/watchOne';
import { UserTypes } from '@mymeeinc/types/user';
import { useSmartFetch } from './useSmartFetch';

export function useAcuityAvailability(coach_id: string = 'first'): AcuityHookFn {
  const { setIsLoading: _setIsLoading } = useRealmApp();
  const graphql = useApolloClient(true);
  const [isLoadingInternal, setIsLoadingInternal] = useState<boolean>(true);
  const { appUserType } = useSettings();
  if (appUserType === UserTypes.ADMIN) {
  }
  const componentIdentifier = ['useAcuity', coach_id].join(':');
  const [object, setObject] = useState<AcuityAvailability>({} as AcuityAvailability);

  const setIsLoading = (identifier: string) => (isLoading: boolean) => {
    setIsLoadingInternal(isLoading);
    return _setIsLoading(identifier)(isLoading);
  };
  const fetcher = async () => {
    const setLoading = setIsLoading(componentIdentifier + ':fetcher');
    try {
      setLoading(true);
      let queryInputHead = '';
      let queryInputBody = '';
      let queryVariables = {};
      if (coach_id !== 'first') {
        queryInputHead = `($coach_id: String!)`;
        queryInputBody = `(query: { coach_id: $coach_id })`;
        queryVariables = { coach_id };
      }
      const query = gql`
        query getAcuityAvailability${queryInputHead} {
          acuityAvailability${queryInputBody} {
            _id
            calendar_id
            appointment_type_id
            coach_id
            location
            timezone
            daily_availabilities {
              availableSlots {
                slotsAvailable
                valid
                time
              }
              date
            }
          }
        }
      `;
      const { data } = await graphql.query({ query, variables: { ...queryVariables } });
      const { acuityAvailability } = data;
      return acuityAvailability || {};
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const { invalidateCache } = useSmartFetch(componentIdentifier, {
    set: (object: AcuityAvailability) => {
      setObject(object);
      setIsLoadingInternal(false);
    },
    fetcher,
    watcher: watchOne,
    collectionName: 'acuity_availabilities',
    key: coach_id,
    throttle: 3000,
    callerRef: componentIdentifier,
  });

  const insert: InsertAcuityFn = async ({
    coach_id,
    calendar_id,
    appointment_type_id,
    location,
    timezone,
    schedulingUrl,
    duration,
  }) => {
    if (!graphql) {
      throw new Error('graphql not ready');
    }
    const setLoading = setIsLoading(componentIdentifier + ':insert');
    try {
      invalidateCache();
      setLoading(true);
      const result = await graphql.mutate({
        mutation: gql`
          mutation insertOneAcuityAvailability(
            $appointment_type_id: Float!
            $daily_availabilities: [AcuityDailyAvailabilityInsertInput]
            $calendar_id: Float!
            $coach_id: String!
            $duration: Float!
            $integration_date: DateTime!
            $location: String!
            $schedulingUrl: String!
            $timezone: String!
          ) {
            insertOneAcuityAvailability(
              data: {
                appointment_type_id: $appointment_type_id
                daily_availabilities: $daily_availabilities
                calendar_id: $calendar_id
                coach_id: $coach_id
                duration: $duration
                integration_date: $integration_date
                location: $location
                schedulingUrl: $schedulingUrl
                timezone: $timezone
              }
            ) {
              _id
              calendar_id
              appointment_type_id
            }
          }
        `,
        variables: {
          appointment_type_id,
          daily_availabilities: [],
          calendar_id,
          coach_id,
          duration,
          integration_date: new Date(),
          location,
          schedulingUrl,
          timezone,
        },
      });
      console.log({ result });
      return { success: true, msg: '' };
    } catch (err: any) {
      if (err.message.match(/^Duplicate key error/)) {
        console.warn(
          `The following error means that we tried to insert a todo multiple times (i.e. an existing todo has the same _id). In this app we just catch the error and move on. In your app, you might want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
        );
      }
      console.error(err);
      return { success: false, msg: err.message };
    } finally {
      setLoading(false);
    }
  };
  const remove: RemoveAcuityFn = async ({ _id }) => {
    if (!graphql) {
      throw new Error('graphql not ready');
    }
    const setLoading = setIsLoading(componentIdentifier + ':remove');

    try {
      invalidateCache();
      setLoading(true);

      const result = await graphql.mutate({
        mutation: gql`
          mutation deleteOneAcuityAvailability($_id: ObjectId) {
            deleteOneAcuityAvailability(query: { _id: $_id }) {
              _id
            }
          }
        `,
        variables: { _id },
      });
      console.log({ result });
      return { success: true, msg: '' };
    } catch (err: any) {
      if (err.message.match(/^Duplicate key error/)) {
        console.warn(
          `The following error means that we tried to insert a todo multiple times (i.e. an existing todo has the same _id). In this app we just catch the error and move on. In your app, you might want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
        );
      }
      console.error(err);
      return { success: false, msg: err.message };
    } finally {
      setLoading(false);
    }
  };

  return {
    insert,
    remove,
    loading: isLoadingInternal,
    object,
  };
}
