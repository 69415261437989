import { AllUnits, ImperialUnits, MetricUnits, OtherUnits } from '../tags';
import { ObjectId } from 'bson';
import { PartialBy } from '../generic';

export interface Food {
  _id: ObjectId;
  name: string;
  parent_id: string | null;
  is_enabled: boolean;
  image?: string | null;
  tagged_user_count: number;
}

export interface Symptom {
  _id: ObjectId;
  name: string;
  parent_id: string | null;
  is_enabled: boolean;
  image?: string | null;
  tagged_user_count: number;
  is_generic?: boolean;
}

export interface Diagnosis {
  _id: ObjectId;
  name: string;
  parent_id: string | null;
  is_enabled: boolean;
  image?: string | null;
  tagged_user_count: number;
  symptom_ids: string[];
}

export interface DayToDay {
  _id: ObjectId;
  name: string;
  parent_id: string | null;
  is_enabled: boolean;
  is_trackable: boolean;
  image?: string | null;
  tagged_user_count: number;
  inputs?: DayToDayInput[];
}

export enum DayToDayInputType {
  Number = 'number',
  ONE_OF_CHILDREN = 'one_of_children',
  MANY_OF_CHILDREN = 'many_of_children',
}

export type DayToDayInput = {
  _id: ObjectId;
  title: string;
  default_value?: number;
  allowed_units: AllUnits[];
  type: DayToDayInputType;
  is_enabled: boolean;
};

export type DayToDayInputResponse = {
  input_id: string;
  type: DayToDayInputType;
  value: string | string[];
  unit: MetricUnits | ImperialUnits | OtherUnits;
};

export enum ContentTypes {
  Diagnosis = 'diagnosis',
  Symptom = 'symptom',
  Food = 'food',
  DayToDay = 'day_to_day',
}
export type ContentTypeEdit = PartialBy<
  Omit<ContentRow, 'tagged_user_count' | 'display_name' | '_id' | 'parent_id'>,
  'is_trackable' | 'symptom_ids' | 'is_generic'
> & {
  _id?: ObjectId;
  parent_id?: string;
};
export type ContentTypeInsert = Omit<ContentTypeEdit, '_id' | 'tagged_user_count'>;
export type ContentTypeUnion = Food & Symptom & Diagnosis & DayToDay;
export type ContentRow = ContentTypeUnion & {
  display_name: string;
  __typename?: string;
};

export type ContentInsertFn = (props: ContentTypeInsert) => Promise<boolean | string>;
export type ContentUpdateFn = (props: ContentTypeEdit) => Promise<boolean | string>;

export type ContentHookFn = {
  insert: ContentInsertFn;
  update: ContentUpdateFn;
  remove: (_id: ObjectId) => Promise<void>;
  list: ContentRow[];
};
