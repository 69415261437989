// import React from 'react';

// highlight
import './utils/highlight';

// scroll bar
import 'simplebar/src/simplebar.css';

// lightbox
import 'react-image-lightbox/style.css';

// editor
import 'react-quill/dist/quill.snow.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
// redux
import { store } from './redux/store';
// error reporting
import * as Sentry from '@sentry/react';

// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import { RealmAppProvider } from './components/RealmApp';

// theme
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/mui/animate/MotionLazyContainer';
import { ProgressBarStyle } from './components/mui/ProgressBar';
import { ChartStyle } from './components/mui/chart';

export const MainFrame = ({
  realm_config,
  name,
  version,
  sentry_init,
  defaultSettings,
  children,
}: {
  children: any;
  realm_config: any;
  name: string;
  version: string;
  sentry_init: any;
  defaultSettings: any;
}) => {
  const versionPostfix =
    location.hostname === 'localhost'
      ? '-localhost'
      : import.meta.env.MODE === 'development'
      ? `-${import.meta.env.VITE_GITHUB_SHA}`
      : '';

  Sentry.init({
    ...sentry_init,
    release: `${name}@${version}${versionPostfix}`,
    enabled: location.hostname !== 'localhost',
    environment: import.meta.env.MODE,
    debug: import.meta.env.MODE !== 'production',
    integrations: [new Sentry.BrowserTracing({})],
    tracesSampleRate: import.meta.env.MODE !== 'production' ? 1.0 : 0.2,
  });
  return (
    <SettingsProvider defaultSettings={defaultSettings}>
      <RealmAppProvider realm_config={realm_config}>
        <HelmetProvider>
          <ReduxProvider store={store}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CollapseDrawerProvider>
                <BrowserRouter>
                  <MotionLazyContainer>
                    <ThemeProvider>
                      <ThemeSettings>
                        <NotistackProvider>
                          <>
                            <ProgressBarStyle />
                            <ChartStyle />
                            {children}
                          </>
                        </NotistackProvider>
                      </ThemeSettings>
                    </ThemeProvider>
                  </MotionLazyContainer>
                </BrowserRouter>
              </CollapseDrawerProvider>
            </LocalizationProvider>
          </ReduxProvider>
        </HelmetProvider>
      </RealmAppProvider>
    </SettingsProvider>
  );
};
