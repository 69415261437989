import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Avatar, ListItemAvatar, ListItemText, MenuItem, Typography } from '@mui/material';
import { UserCustomDataUnion, UserRealmContextData, UserTypes } from '@mymeeinc/types/user';
import { CoachCustomDatum, MemberCustomDatum } from '@mymeeinc/types/user/customData';
// components
import Iconify from '@mymeeinc/shared-web/components/mui/Iconify';
import Scrollbar from '@mymeeinc/shared-web/components/mui/Scrollbar';
import MenuPopover from '@mymeeinc/shared-web/components/mui/MenuPopover';
import { IconButtonAnimate } from '@mymeeinc/shared-web/components/mui/animate';
import { useNavigate } from 'react-router-dom';

import useSettings from '../../../hooks/useSettings';
import { Projects } from '@mymeeinc/types/config';
import { customData2UserData, useRealmApp } from '../../../components/RealmApp';
import { useCoaches, useMembers } from '../../../hooks/users/useUsers';
import { useNavConfigContext } from '@mymeeinc/shared-web/contexts/NavConfigContext';

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 64;

// ----------------------------------------------------------------------

export const getContacts = ({
  project,
  user,
  coaches,
  members,
  isLoading,
}: {
  project: Projects;
  coaches: CoachCustomDatum[];
  members: MemberCustomDatum[];
  user: UserCustomDataUnion;
  isLoading: boolean;
}): { title: string; users: (UserRealmContextData | null)[]; isLoading: boolean } => {
  let title;
  if (project === Projects.ADMIN) {
    title = 'Coaches';
    return {
      title,
      users: coaches.map((user) =>
        customData2UserData(UserTypes.COACH, user as UserCustomDataUnion)
      ),
      isLoading,
    };
  } else if (project === Projects.COACH) {
    title = 'Assigned members';
    return {
      title,
      users: members.map((user) =>
        customData2UserData(UserTypes.MEMBER, user as UserCustomDataUnion)
      ),
      isLoading,
    };
  }
  title = 'Your coach';

  return {
    title,
    users: [
      customData2UserData(
        UserTypes.COACH,
        coaches.find((coach) => user.coach_id === coach.coach_id) as UserCustomDataUnion
      ),
    ],
    isLoading: isLoading,
  };
};

export default function ContactsPopover() {
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const { project } = useSettings();
  const { user } = useRealmApp();
  const { users: coaches, isLoading: isLoadingCoaches } = useCoaches('ContactsPopover');
  const { users: members, isLoading: isLoadingMembers } = useMembers('ContactsPopover');
  const navigate = useNavigate();
  const { PATH_LOGGED_IN } = useNavConfigContext();
  const { title, users } = getContacts({
    user,
    coaches,
    members,
    isLoading: isLoadingCoaches || isLoadingMembers,
    project,
  });

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <Iconify icon={'eva:people-fill'} width={20} height={20} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 320,
          '& .MuiMenuItem-root': {
            px: 1.5,
            height: ITEM_HEIGHT,
            borderRadius: 0.75,
          },
        }}
      >
        <Typography variant="h6" sx={{ p: 1.5 }}>
          {title} <Typography component="span">({users.length})</Typography>
        </Typography>

        <Scrollbar sx={{ height: ITEM_HEIGHT * 6 }}>
          {users.map((contact) => (
            <MenuItem
              key={String(contact?._id)}
              onClick={() => {
                navigate(PATH_LOGGED_IN.chat.view(contact?.user_id));
                handleClose();
              }}
            >
              <ListItemAvatar sx={{ position: 'relative' }}>
                <Avatar src={contact?.picture} />
                {/*<BadgeStatus*/}
                {/*  status={contact.status}*/}
                {/*  sx={{ position: 'absolute', right: 1, bottom: 1 }}*/}
                {/*/>*/}
              </ListItemAvatar>

              <ListItemText
                primaryTypographyProps={{ typography: 'subtitle2', mb: 0.25 }}
                secondaryTypographyProps={{ typography: 'caption' }}
                primary={`${contact?.displayName}`}
                secondary={''}
              />
            </MenuItem>
          ))}
        </Scrollbar>
      </MenuPopover>
    </>
  );
}
