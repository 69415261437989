import { UserTypes } from '@mymeeinc/types/user';

import { ReadFields, UserRules, WriteFields } from '@mymeeinc/types/graphql/rules/types';
import { getReadRule, getWriteRule } from '../util-hooks';

const USER_RULES: UserRules = {
  [UserTypes.ADMIN]: {
    reads: {
      [UserTypes.ADMIN]: () => [
        ...getUserReadRule(UserTypes.COACH, UserTypes.ADMIN),
        'email',
        'is_enabled',
        'account_created_at',
      ],
      [UserTypes.COACH]: () => [...getUserReadRule(UserTypes.COACH, UserTypes.COACH), 'is_enabled'],
      [UserTypes.MEMBER]: () => [...getUserReadRule(UserTypes.COACH, UserTypes.MEMBER)],
    },
    writes: {
      [UserTypes.ADMIN]: {
        inputs: [['admin_id', 'String!']],
        fields: [
          ['first_name', 'String!'],
          ['last_name', 'String!'],
          ['is_enabled', 'Boolean!'],
        ],
      },
      [UserTypes.COACH]: {
        inputs: [['coach_id', 'String!']],
        fields: () => [
          ...getUserWriteRule(UserTypes.COACH, UserTypes.COACH).fields,
          ['is_enabled', 'Boolean!'],
        ],
      },
      [UserTypes.MEMBER]: {
        inputs: [['member_id', 'String!']],
        fields: () => [
          ...getUserWriteRule(UserTypes.COACH, UserTypes.MEMBER).fields,
          ['subscription_start_date', 'DateTime'],
          ['subscription_start_date_unset', 'Boolean'],
          ['subscription_end_date', 'DateTime'],
          ['subscription_end_date_unset', 'Boolean'],
          ['is_enabled', 'Boolean!'],
        ],
      },
    },
  },
  [UserTypes.COACH]: {
    reads: {
      [UserTypes.ADMIN]: () => [...getUserReadRule(UserTypes.MEMBER, UserTypes.ADMIN)],
      [UserTypes.COACH]: () => [
        ...getUserReadRule(UserTypes.MEMBER, UserTypes.COACH),
        'acuity_access_token',
        'birthdate',
        'gender',
        'account_created_at',
        'assigned_members',
        'email',
        'phone_number',
        'address',
        'country',
        'state',
        'city',
        'zip_code',
      ],
      [UserTypes.MEMBER]: () => [
        ...getUserReadRule(UserTypes.MEMBER, UserTypes.MEMBER),
        'is_enabled',
      ],
    },
    writes: {
      [UserTypes.COACH]: {
        inputs: [['coach_id', 'String!']],
        fields: () => [
          ['first_name', 'String!'],
          ['last_name', 'String!'],
          ['phone_number', 'String!'],
          ['address', 'String!'],
          ['country', 'String'],
          ['country_unset', 'Boolean'],
          ['state', 'String!'],
          ['birthdate', 'DateTime'],
          ['birthdate_unset', 'Boolean'],
          ['gender', 'String'],
          ['gender_unset', 'Boolean'],
          ['city', 'String!'],
          ['zip_code', 'String!'],
          ['acuity_access_token', 'String'],
        ],
      },
      [UserTypes.MEMBER]: {
        inputs: [['member_id', 'String!']],
        fields: () => [
          ...getUserWriteRule(UserTypes.MEMBER, UserTypes.MEMBER).fields,
          ['tags_day_to_day', '[CoachCuratedDayToDayTagUpdateInput]'],
          ['tags_symptom', '[CoachCuratedSymptomTagUpdateInput]'],
          ['tags_food', '[CoachCuratedFoodTagUpdateInput]'],
          ['tags_diagnosis', '[CoachCuratedDiagnosisTagUpdateInput]'],
          ['tags_user_diagnosis', '[UserSuggestedDiagnosisTagUpdateInput]'],
          ['tags_user_symptom', '[UserSuggestedSymptomTagUpdateInput]'],
        ],
      },
    },
  },
  [UserTypes.MEMBER]: {
    reads: {
      [UserTypes.MEMBER]: [
        '_id',
        'member_id',
        'coach_id',
        'first_name',
        'last_name',
        'picture',
        'email',
        'phone_number',
        'address',
        'country',
        'state',
        'city',
        'zip_code',
        'birthdate',
        'gender',
        'nickname',
        'account_created_at',
        'subscription_start_date',
        'subscription_end_date',
        [
          'tags_day_to_day',
          [
            'day_to_day_id',
            'alias',
            'created_at',
            'created_by',
            'is_enabled',
            [
              'goals',
              [
                'input_id',
                'impact',
                'input_type',
                'goal_type',
                'action',
                'selected_children',
                'unit',
                'target_amount',
                'is_enabled',
              ],
            ],
          ],
        ],
        [
          'tags_diagnosis',
          ['diagnosis_id', 'alias', 'date', 'is_enabled', 'created_at', 'created_by'],
        ],
        ['tags_food', ['food_id', 'alias', 'is_enabled', 'created_at', 'created_by']],
        ['tags_symptom', ['symptom_id', 'alias', 'is_enabled', 'created_at', 'created_by']],
        ['tags_user_symptom', ['symptom_id', 'alias', 'created_at', 'created_by']],
        ['tags_user_diagnosis', ['diagnosis_id', 'alias', 'date', 'created_at', 'created_by']],
      ],
      [UserTypes.COACH]: ['_id', 'coach_id', 'first_name', 'last_name', 'picture'],
      [UserTypes.ADMIN]: ['_id', 'admin_id', 'first_name', 'last_name', 'picture'],
    },
    writes: {
      [UserTypes.MEMBER]: {
        inputs: [['member_id', 'String!']],
        fields: [
          ['nickname', 'String!'],
          ['first_name', 'String!'],
          ['last_name', 'String!'],
          ['phone_number', 'String!'],
          ['address', 'String!'],
          ['country', 'String'],
          ['country_unset', 'Boolean'],
          ['state', 'String!'],
          ['city', 'String!'],
          ['zip_code', 'String!'],
          ['gender', 'String'],
          ['gender_unset', 'Boolean'],
          ['birthdate', 'DateTime'],
          ['birthdate_unset', 'Boolean'],
        ],
      },
    },
  },
};
export const getUserReadRule = (actor: UserTypes, object: UserTypes): ReadFields => {
  return getReadRule(USER_RULES, actor, object);
};
export const getUserWriteRule = (
  actor: UserTypes,
  object: UserTypes
): { inputs: WriteFields; fields: WriteFields } => {
  return getWriteRule(USER_RULES, actor, object);
};
