// routes
import Router from './routes';
import { NavConfigContext } from '@mymeeinc/shared-web/contexts/NavConfigContext';
import navConfig from './NavConfig';
import { PATH_LOGGED_IN, PATH_GUEST } from './routes/paths';
import { useAcuityAvailability } from '@mymeeinc/shared-web/hooks/useAcuityAvailability';
import { useChat } from '@mymeeinc/shared-web/hooks/useChat';

export default function App() {
  const { object } = useAcuityAvailability();
  const { conversations } = useChat('mainAppForNavConfig');
  return (
    <NavConfigContext.Provider
      value={{ navConfig: navConfig(conversations, object), PATH_LOGGED_IN, PATH_GUEST }}
    >
      <Router />
    </NavConfigContext.Provider>
  );
}
