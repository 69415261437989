import { version } from '../../package.json';
import { UserTypes } from '@mymeeinc/types/user';

import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import { Loadable } from '@mymeeinc/shared-web/routes';

import { useNavConfigContext } from '@mymeeinc/shared-web/contexts/NavConfigContext';
import { useMemberProfileTabs } from '@mymeeinc/shared-web/hooks/useMemberProfileTabs';
// layouts
import LoggedInLayout from '@mymeeinc/shared-web/layouts/loggedIn';
import LogoOnlyLayout from '@mymeeinc/shared-web/layouts/LogoOnlyLayout';
// guards
import GuestGuard from '@mymeeinc/shared-web/guards/GuestGuard';
import AuthGuard from '@mymeeinc/shared-web/guards/AuthGuard';

export default function Router() {
  const { PATH_LOGGED_IN } = useNavConfigContext();

  return useRoutes([
    {
      path: 'guest',
      element: (
        <GuestGuard>
          <LogoOnlyLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: 'login',
          element: <Login />,
        },
        {
          path: 'redirect',
          element: <Redirect />,
        },
      ],
    },

    // Dashboard Routes
    {
      path: '*',
      element: (
        <AuthGuard>
          <LoggedInLayout version={version} />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard" replace />, index: true },
        { path: 'dashboard', element: <Landing /> },
        {
          path: 'user',
          children: [{ path: 'account', element: <UserAccount /> }],
        },
        { path: 'acuity', element: <Acuity /> },
        { path: 'acuity/redirect', element: <AcuityRedirect /> },
        {
          path: 'member',
          children: [
            {
              path: '*',
              element: (
                <UserList
                  key={'member_list'}
                  userType={UserTypes.MEMBER}
                  userText={'Member'}
                  routes={PATH_LOGGED_IN.member}
                />
              ),
            },
            {
              path: ':id/:tab',
              element: (
                <UserEdit
                  key={'member_profile_tab'}
                  userType={UserTypes.MEMBER}
                  routes={PATH_LOGGED_IN.member}
                  userTabs={useMemberProfileTabs}
                />
              ),
            },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'permission-denied', element: <PermissionDenied /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
  ]);
}

// AUTHENTICATION
const Redirect = Loadable(lazy(() => import('@mymeeinc/shared-web/pages/auth/Redirect')));
const Login = Loadable(lazy(() => import('@mymeeinc/shared-web/pages/auth/LoginWithGoogle')));
// COACH SPECIFIC
const Landing = Loadable(lazy(() => import('@mymeeinc/shared-web/pages/Landing')));
const Acuity = Loadable(lazy(() => import('../pages/Acuity')));
const AcuityRedirect = Loadable(lazy(() => import('../pages/AcuityRedirect')));

// USER
const UserAccount = Loadable(lazy(() => import('@mymeeinc/shared-web/pages/myAccount/MyAccount')));

const UserList = Loadable(lazy(() => import('@mymeeinc/shared-web/pages/user/UserList')));
const UserEdit = Loadable(lazy(() => import('@mymeeinc/shared-web/pages/user/UserEdit')));
// APP
const Chat = Loadable(lazy(() => import('@mymeeinc/shared-web/pages/Chat')));
const Calendar = Loadable(lazy(() => import('@mymeeinc/shared-web/pages/CalendarPage')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(
  lazy(() => import('@mymeeinc/shared-web/pages/dashboard/PermissionDenied'))
);

// MAIN
const Page500 = Loadable(lazy(() => import('@mymeeinc/shared-web/pages/Page500')));
const Page403 = Loadable(lazy(() => import('@mymeeinc/shared-web/pages/Page403')));
const Page404 = Loadable(lazy(() => import('@mymeeinc/shared-web/pages/Page404')));
